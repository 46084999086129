.modal {
    width: fit-content;
    height: auto;
}

.modal-dialog {
    width: fit-content;
    height: auto;
}

.modal-header {
    width: fit-content;
    height: auto;
    font-size: xx-small;
}

.modal-title {
    width: fit-content;
    height: auto;
    font-size: xx-small;
}


